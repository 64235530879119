import React from 'react';
import Highlighter from 'react-highlight-words';

interface MarkupProps {
    content: string;
    className?: string;
    highlightwords?: string[];
    allowedTags?: string[];
    allowedAttributes?: string[];
}

const Markup: React.FC<MarkupProps> = props => {
    return (
        <React.Fragment>
            <style jsx>{`
                :global(.Markup ul) {
                    list-style: inherit;
                }

                :global(.Markup ol) {
                    list-style: inherit;
                }
            `}</style>

            {props.highlightwords ? (
                <div className={'Markup ' + props.className}>
                    <Highlighter
                        sanitize={text => text}
                        highlightClassName="MarkupHighlight"
                        searchWords={props.highlightwords}
                        autoEscape={false}
                        textToHighlight={props.content}
                    />
                </div>
            ) : (
                <div className={'Markup ' + props.className} dangerouslySetInnerHTML={{ __html: props.content }} />
            )}
        </React.Fragment>
    );
};

Markup.defaultProps = {
    className: '',
    allowedTags: [],
    allowedAttributes: [],
};

export default Markup;
